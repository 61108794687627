// 护理员详情
<template>
  <div class="big_box">
    <TitleNav class="">
      <template #one>
        <span @click="go('home')" class="cursor"> 首页 > </span>
      </template>
      <template #two>
        <span @click="go('customerList')" class="cursor"> 客户管理列表 > </span>
      </template>
      <template #three>
        <span class="cursor"> 访谈 </span>
      </template>
      <template v-slot:slotCon>
        <div class="background">
          <div class="title">新建访谈访谈记录</div>
          <a-form :form="formData">
            <a-row :gutter="24">
              <a-col :span="4">
                <a-form-item label="服务对象姓名"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <!-- <input
                  placeholder="请填写姓名"
                  class="userName"
                  v-model="formData.name"
                /> -->
                <a-select @change="name" placeholder="请选择">
                  <a-select-option
                    v-for="(list, index) in list"
                    :key="index"
                    :value="index"
                    >{{ list.name }}</a-select-option
                  >
                </a-select>
              </a-col>
              <a-col :span="4">
                <a-form-item label="服务对象年龄"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <input
                  placeholder="请填写年龄"
                  class="userName"
                  v-model="formData.age"
                />
              </a-col>
              <a-col :span="4">
                <a-form-item label="服务对象身高"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <input
                  placeholder="请填写身高（厘米）"
                  class="userName"
                  v-model="formData.height"
                />
              </a-col>
              <a-col :span="4">
                <a-form-item label="服务对象体重"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <input
                  placeholder="请填写体重（公斤）"
                  class="userName"
                  v-model="formData.weight"
                />
              </a-col>
              <a-col :span="4">
                <a-form-item label="服务对象性别"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select placeholder="请选择性别" width="100%" @change="sex">
                  <a-select-option value="1">男性</a-select-option>
                  <a-select-option value="2">女性</a-select-option>
                </a-select>
              </a-col>
              <a-divider />
              <a-col :span="4">
                <a-form-item label="需求服务类型"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select @change="nurseType" placeholder="请选择">
                  <!-- <a-select-option
                    v-for="(Type, index) in Type"
                    :key="index"
                    :value="Type.value"
                    >{{ Type.text }}</a-select-option
                  > -->
                  <a-select-option value="12">
                    24小时居家服务
                  </a-select-option>
                  <a-select-option value="13">
                    院内护理服务
                  </a-select-option>
                  <a-select-option value="14">
                    为老钟点服务
                  </a-select-option>
                </a-select>
              </a-col>

              <a-col :span="4">
                <a-form-item label="服务人员护理周期"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select
                  placeholder="请选择"
                  width="100%"
                  @change="medicalInsuranceCycle"
                >
                  <a-select-option value="1">做六休一</a-select-option>
                  <a-select-option value="2">半月一休</a-select-option>
                  <a-select-option value="3">无休</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="4">
                <a-form-item label="有无长护险"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select
                  placeholder="请选择"
                  width="100%"
                  @change="medicalInsuranceFlag"
                >
                  <a-select-option value="10">有</a-select-option>
                  <a-select-option value="11">没有</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="4">
                <a-form-item label="长护险等级"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select
                  v-if="show"
                  placeholder="请选择长护险等级"
                  width="100%"
                  @change="medicalInsuranceLevel"
                >
                  <a-select-option value="1">一级</a-select-option>
                  <a-select-option value="2">二级</a-select-option>
                  <a-select-option value="3">三级</a-select-option>
                  <a-select-option value="4">四级</a-select-option>
                  <a-select-option value="5">五级</a-select-option>
                  <a-select-option value="6">六级</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="4">
                <a-form-item label="服务对象起夜情况"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <input
                  placeholder="请填写起夜情况（次数）"
                  class="userName"
                  v-model="formData.getUpNum"
                />
              </a-col>
              <a-col :span="4">
                <a-form-item label="家中人数"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select placeholder="请选择" width="100%" @change="familyNum">
                  <a-select-option value="1">独居</a-select-option>
                  <a-select-option value="2">2人</a-select-option>
                  <a-select-option value="3">3人</a-select-option>
                  <a-select-option value="4">4人</a-select-option>
                  <a-select-option value="5">5人</a-select-option>
                  <a-select-option value="6">6人</a-select-option>
                  <a-select-option value="7">7人</a-select-option>
                  <a-select-option value="8">8人</a-select-option>
                  <a-select-option value="9">9人</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="4">
                <a-form-item label="是否需要做饭"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select
                  placeholder="请选择是否需要做饭"
                  width="100%"
                  @change="needCook"
                >
                  <a-select-option value="10">是</a-select-option>
                  <a-select-option value="11">否</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="4">
                <a-form-item label="是否服务人员住宿"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select
                  placeholder="请选择服务人员住宿"
                  width="100%"
                  @change="needStay"
                >
                  <a-select-option value="10">是</a-select-option>
                  <a-select-option value="11">否</a-select-option>
                </a-select>
              </a-col>
              <a-divider />
              <a-col :span="4">
                <a-form-item label="协商价格"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <!-- <input placeholder="协商价格" class="userName" v-model="formData.negotiatePrice" /> -->
                <a-input-group compact>
                  <input class="userName1" v-model="formData.negotiatePrice" />
                  <a-select default-value="1" @change="emType">
                    <a-select-option value="1"> 元/月 </a-select-option>
                    <a-select-option value="2"> 元/天 </a-select-option>
                    <a-select-option value="3"> 元/小时 </a-select-option>
                  </a-select>
                </a-input-group>
              </a-col>
              <a-col :span="4">
                <a-form-item label="是否接受节假日 另议价格"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-select
                  placeholder="请选择"
                  width="100%"
                  @change="changePrice"
                >
                  <a-select-option value="10">是</a-select-option>
                  <a-select-option value="11">否</a-select-option>
                </a-select>
              </a-col>
              <a-divider />
              <a-col :span="4">
                <a-form-item label="服务地址"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <input
                  placeholder="请填写服务地址"
                  class="userName"
                  v-model="formData.serviceDetailAddress"
                />
              </a-col>
              <a-col :span="4">
                <a-form-item label="客户联系电话"> </a-form-item>
              </a-col>
              <a-col :span="8">
                <input
                  placeholder="请填写客户联系电话"
                  class="userName"
                  v-model="formData.mobile"
                  @change="mobile"
                />
              </a-col>
              <a-divider />
              <a-col :span="4">
                <a-form-item label="服务详情要求"> </a-form-item>
              </a-col>
              <a-col :span="18">
                <textarea
                  placeholder="必填"
                  v-model="formData.serviceRequire"
                />
              </a-col>
              <a-col :span="4">
                <a-form-item label="服务人员特殊要求"> </a-form-item>
              </a-col>
              <a-col :span="18">
                <textarea
                  placeholder="必填"
                  v-model="formData.specialRequire"
                />
              </a-col>
              <a-col :span="4">
                <a-form-item label="护理要点和须知"> </a-form-item>
              </a-col>
              <a-col :span="18">
                <textarea placeholder="必填" v-model="formData.notice" />
              </a-col>
              <a-col :span="4">
                <a-form-item label="备注"> </a-form-item>
              </a-col>
              <a-col :span="18">
                <textarea placeholder="备注" v-model="formData.remark" />
              </a-col>
            </a-row>
            <div class="but">
              <button @click="goback" class="goback"><span>返回</span></button>
              <button type="primary" class="submit" @click="submit">
                <span>生成意向单</span>
              </button>
            </div>
          </a-form>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn">
      <template #one>
        <button @click="createUser()" class="btn1 cursor base_white_color">
          创建家庭成员
        </button>
      </template>

      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import { addInterview, getLookInfoClan, getType } from "../api/api";

import { getItem } from "../utils/storage";

export default {
  data() {
    return {
      formData: {},
      list: {},
      Type: {},
      show: false,
    };
  },
  created() {
    let that = this;
    this.formData.consumerId = this.$route.query.consumerId;
    this.formData.accountId = this.$route.query.resultID;
    getLookInfoClan({ id: this.$route.query.resultID }).then((res) => {
      that.list = res.data.result; // 家庭成员信息
      console.log(that.list);
    });
    console.log("================================");
    getType().then((res) => {
      console.log(res.data.result);
      that.Type = res.data.result;
    });
  },
  components: { TitleNav, ebutton },
  methods: {
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "customerList":
          this.$router.push("/customerList");
          break;
        default:
          break;
      }
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    goback() {
      this.$router.push("/lookinfo");
    },
    submit() {
      console.log(this.formData);
      let that = this;
      let info = getItem("userInfo");
      this.formData.salesman = info.id;
      this.formData.salesName = info.realname;
      console.log(this.formData.serviceRequire);
      if (!this.formData.serviceRequire) {
        //校验服务详情要求
        that.$message.warning("请选择服务详情要求!");
        return false;
      }
      if (!this.formData.specialRequire) {
        //校验服务人员特殊要求
        that.$message.warning("请选择服务人员特殊要求!");
        return false;
      }
      if (!this.formData.notice) {
        //校验护理要点和须知
        that.$message.warning("请选择护理要点和须知!");
        return false;
      }
      addInterview(this.formData).then((res) => {
        console.log(res);
        if (res.data.success) {
          that.$confirm({
            title: "保存成功!",
            content: res.data.message,
            okText: "确认",
            onOk() {
              that.$router.push("/customerList");
            },
          });
        } else {
          that.$error({
            title: "保存失败!",
            content: res.data.message,
          });
        }
      });
    },
    //手机号正则
    // mobile(e) {
    //   console.log(e);
    //   var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
    //   alert(reg.test(e))
    //   if (!reg.test(e)) {
    //     alert("手机号码有误，请重填");
    //     return false;
    //   }
    // },
    //因为双向绑定没有用，一下都是赋值方法
    name(e) {
      this.formData.name = this.list[e].name;
      this.formData.age = this.list[e].age;
      this.formData.accountFamilyMemberId = this.list[e].id;
    },
    sex(e) {
      this.formData.sex = e;
    },
    nurseType(e) {
      this.formData.nurseType = e;
    },
    medicalInsuranceCycle(e) {
      this.formData.medicalInsuranceCycle = e;
    },
    medicalInsuranceFlag(e) {
      this.formData.medicalInsuranceFlag = e;
      if (e == "10") {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    medicalInsuranceLevel(e) {
      this.formData.medicalInsuranceLevel = e;
    },
    familyNum(e) {
      this.formData.familyNum = e;
    },
    needCook(e) {
      this.formData.needCook = e;
    },
    needStay(e) {
      this.formData.needStay = e;
    },
    changePrice(e) {
      this.formData.changePrice = e;
    },
    emType(e) {
      this.formData.emType = e;
    },
  },
};
</script>
<style scoped>
.right_position {
  margin-top: 118px;
}
.left_position {
  margin-top: 146px;
}
.big_box {
  position: relative;
}
.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(
    -90deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
}

/********************************************* */

.background .title {
  width: 184px;
  height: 21px;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
  line-height: 29px;
  margin-bottom: 40px;
}
.userName1 {
  width: 43%;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border: 1px solid #ccc;
  margin-left: 0px;
  font-size: 20px;
  color: #333333;
}
.userName {
  width: 320px;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  padding-left: 20px;
  border: 1px solid #ccc;
  margin-left: 0px;
  font-size: 20px;
  color: #333333;
}
input:focus {
  border: none;
  box-shadow: 0px 0px 3px var(--themeColor);
  border: 1px solid var(--themeColor);
}
input:hover {
  border: 1px solid var(--themeColor);
}
.input {
  width: 302px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  opacity: 0.7;
  border: 1px solid #252323;
}

input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #ccc;
}
textarea {
  width: 97%;
  height: 146px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;

  line-height: 50px;
  padding-left: 20px;
  font-size: 20px;
}
textarea:focus {
  border: none;
  box-shadow: 0px 0px 3px var(--themeColor);
  border: 1px solid var(--themeColor);
}
textarea:hover {
  border: 1px solid var(--themeColor);
}
textarea::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #ccc;
}

.goback {
  margin-left: 34px;
  cursor: pointer;
  width: 167px;
  height: 48px;
  background: linear-gradient(-90deg, #98dcc3 0%, #abe3a4 100%);
  border-radius: 6px;
}
.but {
  margin-top: 86px;
}
.goback span {
  width: 40px;
  height: 20px;
  font-size: 20px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
.submit {
  margin-left: 34px;
  cursor: pointer;
  width: 167px;
  height: 48px;
  background: #fea56c linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 6px;
}

.submit span {
  width: 40px;
  height: 20px;
  font-size: 20px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
/* ------------------------------------------------------------ */
:deep(.ant-avatar > img) {
  width: 178px;
  height: 178px;
  border-radius: 6px;
}
:deep(.ant-avatar-square) {
  width: 178px;
  height: 178px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  margin-left: 19px;
}

:deep(.ant-select, form .ant-cascader-picker) {
  width: 322px;
}
:deep(.ant-select-selection-placeholder) {
  padding-left: 10px !important;
  font-size: 20px;
  line-height: 46px !important;
}
:deep(.ant-select-selection-item) {
  height: 50px !important;
  line-height: 46px !important;
  padding-left: 10px !important;
  font-size: 20px;
}
:deep(.ant-input:placeholder-shown) {
  margin-top: 6px;
  width: 97%;
  border-radius: 6px;
}
:deep(.ant-select-selector) {
  height: 50px !important;
  border-radius: 8px !important;
  line-height: 50px !important;
  border: none !important;
  border: 1px solid #ccc !important;
}
:deep(.ant-select-selector:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid var(--themeColor) !important;
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}
:deep(.ant-form label) {
  font-size: 20px;
}
</style>
